








import { Component, Vue } from "vue-property-decorator";
import Hero from "@/sections/Project/Hero/Hero.vue";
import Explain from "@/sections/Project/Explain/Explain.vue";
import DownloadApp from "@/sections/Project/DownloadApp/DownloadApp.vue";

@Component({
  components: {
    Hero,
    Explain,
    DownloadApp,
  },
})
export default class Project extends Vue {}
